import Sponsored from "../../../pubstack/assets/js/sponsored";

// Define site-specific settings
window.siteSettings = {
  nativoIDs: ["1120057", "1120058"],
  nativoID3: "1120059",
};

// Override settings for testing
const firstArticle = $(".pubstack-post-header:first");
if (firstArticle.length) {
  let targeting = firstArticle.data("page_targeting");
  if (targeting?.environment === "test") {
    window.siteSettings.nativoIDs = ["1117850", "1117859"];
    window.siteSettings.nativoID3 = "1120038";
  }
}

// Initialize Sponsored.js once
$(document).ready(() => {
  Sponsored.init();
});
