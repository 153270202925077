/**
 * All global frontend scripts required should be included here.
 *
 * This is the file that Webpack is compiling into main.[hash].js
 */

import "../../pubstack/assets/tvguide"; // load before footer css
import "./sass/footer.scss";

import expandedMenu from "./js/expanded-menu"; // 2KB
import EmailPreferences from "./js/newsletter/preferences";
import EmailUnsubscribe from "./js/newsletter/unsubscribe";
import SubscribeModal from "./js/newsletter/modal";
import NewsletterBlock from "./js/newsletter/block";
import "sticky-kit/dist/sticky-kit.js";
import Analytics from "../../pubstack/assets/js/utils/analytics"; // 4KB
import "./js/blocks/event-upcoming-experiences";
import "./js/blocks/event-vod-promo";

let analyticsInstance = new Analytics();
require("./js/ads/cordial-footer");

import articleOembed from "./js/article/article-oembed";
import Footer from "./js/footer";
import ofTheEssenceArchive from "./js/of-the-essence-archive";
import nativeTout from "./js/native-tout";

require("../../pubstack/assets/js/ads/cls");

import VideoJw from "../../pubstack/assets/js/video-jw";
import IasVideo from "../../pubstack/assets/js/ads/ias-video";
import PlaylistPlayer from "./js/blocks/playlist-player";
import PlaylistModal from "./js/blocks/playlist-schedule";
import "./js/sponsored";
new IasVideo();

$(function () {
	setTimeout(function () {
		analyticsInstance.init();
		new VideoJw().init();
		$(window).on("injector:footer-load", () => {
			Footer.init();
		});
		ofTheEssenceArchive.init();
		nativeTout.init();
		expandedMenu();
		articleOembed();

		// Newsletter Subscribe
		PlaylistModal.init();
		EmailPreferences.init();
		SubscribeModal.init();
		EmailUnsubscribe.init();
		NewsletterBlock.init();
		PlaylistPlayer();
	}, 0);
});
