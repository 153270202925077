/**
 * Implement Nativo in JavaScript
 */
function Sponsored() {
  let queue = [];

  this.init = function () {
    $(window).on(
      "injector:inject_widgets_injected",
      this,
      this.maybeRenderNativo
    );
  };

  const viewPortHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  const rootMargin = Math.floor(viewPortHeight);

  // Observer to detect Nativo
  const nativoObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          const el = $(entry.target);
          renderNativo(el);
          observer.unobserve(entry.target);
        }
      });
    },
    { rootMargin: `0px 0px ${rootMargin}px 0px`, threshold: 0 }
  );

  // Observer to detect if Nativo is rendered; if not, show fallback content
  const nativoRenderObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        const el = $(entry.target);
        const nativoRendered = el.find(".ntv-card").not(":empty");

        if (nativoRendered.length) {
          el.find(
            `.card-blog:not(.ntv-card):gt(${4 - nativoRendered.length})`
          ).remove();
          if (nativoRendered.length === el.find(".ntv-card").length) {
            observer.unobserve(entry.target);
          }
        }

        el.find(".card-blog").not(":empty").last().addClass("last-card-blog");
      });
    },
    { threshold: 0 }
  );

  function renderNativo(appendTo) {
    let IDs = window.siteSettings?.nativoIDs || ["1120057", "1120058"];
    let ID3 = window.siteSettings?.nativoID3 || "1120059";

    $(".ntv-div.n1, .ntv-div.n2").removeClass("n1 n2");

    appendTo
      .append('<div class="card-blog ntv-div n1"></div>')
      .append('<div class="card-blog ntv-div n2"></div>');

    if (
      $(".pubstack-post-header").index(appendTo.closest("article").prev()) >= 1
    ) {
      appendTo.append('<div class="card-blog ntv-div n3"></div>');
      IDs.push(ID3);
    }

    nativoRenderObserver.observe(appendTo[0]);

    if (typeof PostRelease !== "undefined") {
      PostRelease.Start({ ptd: IDs });
    } else {
      console.warn("PostRelease is not defined! Adding IDs to queue.");
      queue = [...queue, ...IDs];
    }
  }

  this.maybeRenderNativo = function (_, $article) {
    const $el = $article.find(".read-more-blog");
    if ($el.length) {
      nativoObserver.observe($el[0]);
    }
  };
}
export default new Sponsored();
